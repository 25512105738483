var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_c('div', {
    staticClass: "login-row login mt-0"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "아이디",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--button mt-8 mt-lg-12"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "휴대전화번호",
      "type": "text"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _c('v-btn', {
    staticClass: "ml-4 ml-8",
    attrs: {
      "color": "grey-b3"
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("인증번호전송")])])], 1), _c('div', {
    staticClass: "login-row mt-8 mt-lg-12"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "인증번호 입력",
      "type": "number"
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-14 mt-lg-24"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("찾기")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }