var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20 mt-lg-40"
  }, [_vm._m(0), _c('div', {
    staticClass: "login-row login-row--button mt-0"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "휴대전화번호",
      "type": "text"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _c('v-btn', {
    staticClass: "ml-4 ml-8",
    attrs: {
      "color": "grey-b3"
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("인증번호전송")])])], 1), _c('div', {
    staticClass: "login-row mt-8 mt-lg-12"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "label": "인증번호",
      "type": "number"
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-14 mt-lg-24"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("찾기")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "page-text text-center mb-20 mb-lg-40"
  }, [_vm._v(" 아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요. "), _c('br'), _vm._v(" 등록된 핸드폰으로 아이디 정보를 전달드립니다. ")]);

}]

export { render, staticRenderFns }