<template>
    <div class="mt-20 mt-lg-40">
        <div class="login-row login mt-0">
            <v-text-field v-model="username" outlined hide-details label="아이디" type="text"></v-text-field>
        </div>
        <div class="login-row login-row--button mt-8 mt-lg-12">
            <v-text-field v-model="phone" outlined hide-details label="휴대전화번호" type="text"></v-text-field>
            <v-btn @click="certify" color="grey-b3" class="ml-4 ml-8"><span class="white--text">인증번호전송</span></v-btn>
        </div>
        <div class="login-row mt-8 mt-lg-12">
            <v-text-field v-model="otp" outlined hide-details label="인증번호 입력" type="number"></v-text-field>
        </div>
        <div class="login-row login-row--submit mt-14 mt-lg-24">
            <v-btn @click="recovery" block large color="primary">찾기</v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components: {
    },
    data() {
        return {
            username: null,
            phone: null,
            otp: null,
        };
    },
    methods: {
        validate(recovery = false) {
            try {
                if (!this.username) throw new Error("아이디를 입력해주세요");
                if (!this.phone) throw new Error("이메일을 입력해주세요");
                if (recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async certify() {
            if (this.validate()) {
                let { phone, username } = this;
                await api.v1.users.certify.post({
                    type: "phone",
                    kind: "password",
                    phone,
                    username,
                });

                alert("인증번호가 전송되었습니다");
            }
        },

        async recovery() {
            if (this.validate(true)) {
                let { otp, phone, username } = this;
                await api.v1.users.recovery.post({
                    type: "phone",
                    kind: "password",
                    otp,
                    phone,
                    username,
                });

                alert("가입한 이메일 정보로 비밀번호가 발송되었습니다");
            }
        },
    },
};
</script>
