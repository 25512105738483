<template>
    <v-btn v-bind="$attrs" @click="click">
        <slot></slot>
        <v-file-input v-model="file" ref="fileInput" v-bind="$attrs" class="d-none" @change="input"></v-file-input>
    </v-btn>
</template>
<script>
export default {
    props: {
        value: { type: File, default: null },
    },
    data: () => ({
        file: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value || null;
        },
        input(file) {
            this.file = null;
            this.$emit("change", file);
            this.$emit("input", file);
        },
        click() {
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
    },
};
</script>