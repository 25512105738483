<template>
    <div class="mt-20 mt-lg-40">
        <p class="page-text text-center mb-20 mb-lg-40">
            아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요. <br>
            등록된 핸드폰으로 아이디 정보를 전달드립니다.
        </p>
        <div class="login-row login-row--button mt-0">
            <v-text-field v-model="phone" outlined hide-details label="휴대전화번호" type="text" />
            <v-btn @click="certify" color="grey-b3" class="ml-4 ml-8"><span class="white--text">인증번호전송</span></v-btn>
        </div>
        <div class="login-row mt-8 mt-lg-12">
            <v-text-field v-model="otp" outlined hide-details label="인증번호" type="number" />
        </div>
        <div class="login-row login-row--submit mt-14 mt-lg-24">
            <v-btn @click="recovery" block large color="primary">찾기</v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components: {
    },
    data() {
        return {
            phone: null,

            otp: null,
        };
    },
    methods: {
        validate(recovery = false) {
            try {
                if (!this.phone) throw new Error("이메일을 입력해주세요");
                if (recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async certify() {
            if (this.validate()) {
                let { phone } = this;
                await api.v1.users.certify.post({
                    type: "phone",
                    kind: "username",
                    phone,
                });

                alert("인증번호가 전송되었습니다");
            }
        },

        async recovery() {
            if (this.validate(true)) {
                let { phone, otp } = this;
                await api.v1.users.recovery.post({
                    type: "phone",
                    kind: "username",
                    phone,
                    otp,
                });

                alert("휴대전화번호로 아이디가 발송되었습니다");
            }
        },
    },
};
</script>
